<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search here..."
            />
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              split
              text="Export"
              variant="outline-primary"
              right
              class="mr-1"
            >
              <b-dropdown-item>
                Option 1
              </b-dropdown-item>
              <b-dropdown-item>
                Option 2
              </b-dropdown-item>
              <b-dropdown-item>
                Option 3
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-center
              split
              text="Import"
              variant="outline-primary"
              right
            >
              <b-dropdown-item>
                Option 1
              </b-dropdown-item>
              <b-dropdown-item>
                Option 2
              </b-dropdown-item>
              <b-dropdown-item>
                Option 3
              </b-dropdown-item>
            </b-dropdown>
            <!--            <v-select-->
            <!--              v-model="statusFilter"-->
            <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
            <!--              :options="statusOptions"-->
            <!--              class="invoice-filter-select"-->
            <!--              placeholder="Select Status"-->
            <!--            >-->
            <!--              <template #selected-option="{ label }">-->
            <!--                <span class="text-truncate overflow-hidden">-->
            <!--                  {{ label }}-->
            <!--                </span>-->
            <!--              </template>-->
            <!--            </v-select>-->
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(invoiceStatus)="data">
        <template v-if="data.value === 0">
          <b-badge
            pill
            variant="light-success"
          >
            Paid
          </b-badge>
        </template>
        <template v-else>
          {{ data.item.invoiceStatus }}
        </template>
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.client.name)"
              :variant="`light-${resolveClientAvatarVariant(data.item.invoiceStatus)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.client.name }}
          </span>
          <small class="text-muted">{{ data.item.client.companyEmail }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)="data">
        <template v-if="data.value === 0">
          <b-badge
            pill
            variant="light-success"
          >
            Paid
          </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <b-button
            :id="`invoice-row-${data.item.id}-edit-icon`"
            v-ripple.400="'rgba(30, 199, 111, 0.15)'"
            v-b-modal.modal-edit-product
            variant="flat-success"
            class="btn-icon rounded-circle"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-tooltip
            title="Edit Product"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-edit-icon`"
          />

          <b-button
            :id="`invoice-row-${data.item.id}-delete-icon`"
            v-ripple.400="'rgba(40, 199, 91, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="confirmText"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <b-tooltip
            title="Delete Product"
            :target="`invoice-row-${data.item.id}-delete-icon`"
          />

          <b-button
            :id="`invoice-row-${data.item.id}-copy-icon`"
            v-ripple.400="'rgba(40, 199, 91, 0.15)'"
            v-b-modal.modal-duplicate
            variant="flat-success"
            class="btn-icon rounded-circle"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>
          <b-tooltip
            title="Duplicate Product"
            :target="`invoice-row-${data.item.id}-copy-icon`"
          />
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BAlert, VBModal,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { codeSSR } from '@/views/table/vue-good-table/code'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BAlert,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 3,
      dir: false,
      pages: ['3', '5', '10'],
      codeSSR,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Brand Code',
          field: 'brandCode',
        },
        {
          label: 'Brand Name',
          field: 'brandName',
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Active', 2: 'Inactive',
      }],
    }
  },
  methods: {
    handleSearch(searching) {
      this.log.push(`The user searched for: ${searching}`)
    },
    handleChangePage(page) {
      this.log.push(`The user changed the page to: ${page}`)
    },
    handlePageChange(active) {
      this.log.push(`the user change page:  ${active}`)
    },
    onSortChange(params) {
      this.log.push(`the user ordered:  ${params[0].type}`)
    },
    // confirm texrt
    confirmText() {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
